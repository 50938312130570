import React from "react";

import styles from "./PremiumIntro.module.css";

const PremiumIntro = () => (
  <div className={styles.PremiumIntro}>
    <h2 className={styles.PremiumIntroHeader}>Why premium</h2>
    <p className={styles.PremiumIntroText}>
      We are hardly working to bring more advanced features to you as soon as
      possible. Our vision is to keep basic functionality free forever. At the
      same time, provide a useful set of tools that will help you to grow
      professionally, keep finding new clients, and new ways to increase an
      income for your expertise as a part of the premium program.
    </p>
    <p className={styles.PremiumIntroTextRight}>
      Stay connected to us and you will get more information soon.
    </p>
  </div>
);

export default PremiumIntro;
