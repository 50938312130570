import React from "react";

import styles from "./PremiumFeatures.module.css";

const PremiumFeatures = () => (
  <div className={styles.PremiumFeatures}>
    <h1 className={styles.PremiumFeaturesHeader}>Premium Features</h1>
    <ul className={styles.PremiumFeaturesList}>
      <li className={styles.PremiumFeaturesItem}>
        <div className={`${styles.PremiumFeaturesImage} ${styles.time}`} />
        <div className={styles.PremiumFeaturesTitle}>coming soon</div>
        <p className={styles.PremiumFeaturesText}>
          Some nice and advanced feature will come very soon here. Be prepared
          as nvever before.
        </p>
      </li>
      <li className={styles.PremiumFeaturesItem}>
        <div className={`${styles.PremiumFeaturesImage} ${styles.user}`} />
        <div className={styles.PremiumFeaturesTitle}>coming soon</div>
        <p className={styles.PremiumFeaturesText}>
          Even more nice and advanced feature will come very soon here. Be
          prepared as nvever before.
        </p>
      </li>
      <li className={styles.PremiumFeaturesItem}>
        <div className={`${styles.PremiumFeaturesImage} ${styles.base}`} />
        <div className={styles.PremiumFeaturesTitle}>coming soon</div>
        <p className={styles.PremiumFeaturesText}>
          Absolutely the best thing ever. Stay tuned. Keep calm. Be happy. Be
          healthy.
        </p>
      </li>
    </ul>
  </div>
);

export default PremiumFeatures;
