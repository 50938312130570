import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import Info from "./premium-page/Info";
import PremiumFeatures from "./premium-page/PremiumFeatures";
import PremiumIntro from "./premium-page/PremiumIntro";

const Pricing = () => (
  <Layout>
    <SEO title="Pricing" />
    <section>
      <Info />
    </section>
    <div>
      <PremiumIntro />
    </div>
    <div>
      <PremiumFeatures />
    </div>
  </Layout>
);

export default Pricing;
